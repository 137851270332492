<template>
  <v-container fluid app>
    <div class="col-md-12 mb-3">
      <page-title
        :title="activeProject.name"
        :subtitle="activeProject.longForm"
        :useBackBtn="true"
      ></page-title>
    </div>

    <v-row>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="#295099"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4 ylw">
                Project Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                {{ activeProject.cards.total }}
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="#295099"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Completed Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                {{ activeProject.cards.completed }}
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="#295099"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Ongoing Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                {{ activeProject.cards.ongoing }}
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="#295099"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Pending Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                {{ activeProject.cards.pending }}
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9" md="9">
        <v-card elevation="1">
          <div class="card">
            <div class="card-body">
              <h3 class="header-title text-dark mt-0 mb-3 pa-3">
                Contractual Summary
              </h3>
              <v-row>
                <div class="table-responsive browser_users col-lg-6">
                  <v-simple-table class="table mb-0">
                    <thead class="thead-light"></thead>
                    <tbody>
                    <tr>
                      <td class="text-title">Project Name (Code):</td>
                      <td class="text-subtitle">{{ activeProject.name }}</td>
                    </tr>

                    <tr>
                      <td class="text-title">Project Location</td>
                      <td class="text-subtitle">{{ activeProject.contractual.location }}</td>
                    </tr>

                    <tr>
                      <td class="text-title">Contractor(s) Name:</td>
                      <td class="text-subtitle">{{ activeProject.contractual.contractor }}</td>
                    </tr>

                    <tr>
                      <td class="text-title">
                        Contract Duration (in Months)
                      </td>
                      <td class="text-subtitle">{{ activeProject.contractual.duration }}</td>
                    </tr>

                    <tr>
                      <td class="text-title">Contract Signature Date</td>
                      <td class="text-subtitle">2016-07-10</td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <div class="table-responsive browser_users col-lg-6">
                  <v-simple-table class="table mb-0">
                    <tbody>
                    <tr>
                      <td class="text-title">Commencement Date</td>
                      <td class="text-subtitle">2017-01-12</td>
                    </tr>
                    <tr>
                      <td class="text-title">Contract competion Date</td>
                      <td class="text-subtitle">2022-09-21</td>
                    </tr>
                    <tr>
                      <td class="text-title">
                        Extension of Time (EOT No.1) Completion date
                      </td>
                      <td class="text-subtitle"></td>
                    </tr>
                    <tr>
                      <td class="text-title">
                        Extension of Time (EOT No.3) Completion Date
                      </td>
                      <td class="text-subtitle"></td>
                    </tr>
                    <tr>
                      <td class="text-title">Type of Finance</td>
                      <td class="text-subtitle">{{ activeProject.contractual.finance }}</td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="3" md="3">
        <template>
          <v-card
            max-width="450"
            class="mx-auto"
          >
            <v-toolbar
              color="#295099"
              dark
              flat
              dense
            >

              <v-toolbar-title>Implementing Institutions</v-toolbar-title>

            </v-toolbar>

            <v-list three-line>
              <template v-for="(institution, index) in activeProject.institutions">


                <v-list-item :key="index">
                  <div style="margin-right: 5px">
                    <img :src="institution.logo" class="fit-avatar" />
                  </div>

                  <v-list-item-content>
                    <v-list-item-title v-html="institution.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="institution.longForm"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="1" class="flex-column">
          <div class="flex-column" style="padding: 15px">

            <div style="display: flex; justify-content: space-between;">
              <h3 class="header-title mb-2 text-dark">
                Project Progress
              </h3>
              <v-btn small color="primary" to="/projects/gantt-chart">
                <v-icon>mdi-poll</v-icon>
                <span class="pl-1">View Progress in Gantt Chart</span>
              </v-btn>
            </div>
            <v-progress-linear
              :background-color="activeProject.contractual.percent < 40 ? 'red lighten-3': (activeProject.contractual.percent >= 40 && activeProject.contractual.percent < 60 ? 'warning lighten-3': 'success lighten-3')"
              :color="activeProject.contractual.percent < 40 ? 'red darken-2': (activeProject.contractual.percent >= 40 && activeProject.contractual.percent < 60 ? 'warning': 'success')"
              :value="activeProject.contractual.percent"
              height="20"
              rounded
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <div class="flex-column">
              <div class="flex-row mt-1" style="display: flex; flex-direction: row; align-items: center;">
                <v-system-bar lights-out>Project Time:</v-system-bar>
                <span> {{ activeProject.contractual.duration }} Months</span>
              </div>
              <div class="flex-row mt-1" style="display: flex; flex-direction: row; align-items: center;">
                <v-system-bar lights-out>Time Used:</v-system-bar>
                <span> {{ activeProject.contractual.used }} Months</span>
              </div>
              <div class="flex-row mt-1" style="display: flex; flex-direction: row; align-items: center;">
                <v-system-bar lights-out>Remaining Time:</v-system-bar>
                <span> {{ activeProject.contractual.remain }} Months</span>
              </div>
            </div>

          </div>
        </v-card>

      </v-col>
    </v-row>

    <div class="mt-5 col-md-12">
      <v-card flat class="col-md-12">
        <div class="d-flex wrap justfy-space-between align-baseline">
          <h3 class="text-dark">Project Activities</h3>
          <div class="d-flex col justify-end">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  small
                  color="primary white--text"
                  style="margin: 5px"
                ><v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New Activity</span>
            </v-tooltip>
          </div>
        </div>
        <v-data-table :headers="headers" :items="activeProject.activities">
          <template v-slot:item.calories="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}%
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu :key="item.name" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  icon
                  color="primary"
                ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
<!--                <v-list-item link to="/components/project_sub_activity">-->
                <v-list-item link>
                  <v-list-item-title
                  ><v-icon style="height: 16px; font-size: 16px"
                  >mdi-eye</v-icon
                  >
                    View Sub-Activities</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="Asignmentdialog=true" >
                  <v-list-item-title
                  ><v-icon style="height: 16px; font-size: 16px"
                  >mdi-account-eye</v-icon
                  >
                    Assign Reporter</v-list-item-title
                  >
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item link to="">
                  <v-list-item-title
                  ><v-icon style="height: 16px; font-size: 16px"
                  >mdi-delete</v-icon
                  >
                    Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div class="row">
      <div class="col-md-4">
        <v-card elevation="1">
          <v-system-bar lights-out class="m-2">On Site Evidence</v-system-bar>
          <v-carousel
            cycle
            height="400"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item v-for="(slide, i) in activeProject.evidences" :key="i">
              <v-card>
                <template slot="progress">
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                  height="250"
                  :src="slide.img"
                ></v-img>

                <v-card-subtitle>{{ slide.progress }}</v-card-subtitle>

                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <div class="grey--text ms-0">DateDec. 12, 2020</div>
                  </v-row>

                  <div class="my-4 text-subtitle-1">
                    Location: [ Lat: -6.2343722, Long: 35.8278254 ]
                  </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </div>
      <div class="col-md-8">
        <v-card elevation="1">
          <v-system-bar lights-out class="mt-2">Activity Progress</v-system-bar>
          <ProjectTranding :labels="activeProject.activityProgress.labels"
                           :series="activeProject.activityProgress.series" />
        </v-card>
      </div>
    </div>
    <asignment-form :Asignmentdialog="Asignmentdialog"></asignment-form>
  </v-container>
</template>

<script>

import ProjectTranding from "@/modules/me_projects/project_view/ProjectTranding.vue";
import PageTitle from "@/modules/digitaltp_shared/page-title.vue";
import AsignmentForm from "@/modules/digitaltp_projects/digitaltp_components/project_details/AsignmentForm.vue";

export default {
  name: 'ProjectView',
  components: {
    ProjectTranding,
    PageTitle,
    AsignmentForm
  },
  data() {
    return {

      projects: {
        eacop: {
          name: "EACOP",
          longForm: "East African Crude Oil Pipeline",
          cards: {
            total: 5,
            completed: 3,
            ongoing: 1,
            pending: 1
          },
          contractual: {
            location: "East Africa",
            contractor: "TPDC, TotalEnergies, UNOC, CNOOC Limited",
            duration: 36,
            used: 15,
            remain: 21,
            percent: 70,
            finance: "Loan",
          },
          institutions: [
            {
              name: 'TPDC',
              longForm: 'Tanzania Petroleum Development Corporation',
              logo: require("@/assets/tpdc.png")
            }
          ],
          activities: [
            {
              name: "Statistical Information Management System",
              calories: 54,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 6.7 Mill",
              protein: "$ 4.3 Mill",
              expenditure: "$ 2.4 Mill",
              actions: "1%",
            },
            {
              name: "e-Commerce",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "5.6",
              protein: "$ 4.3 Mill",
              expenditure: "$ 1.3 Mill",
              actions: "8%",
            },
            {
              name: "ICT Regulatory",
              calories: 26,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 4.8 Mill",
              protein: "$ 3.9 Mill",
              expenditure: "$ 0.9 Mill",
              actions: "16%",
            },
            {
              name: "e-Commerce",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "5.6",
              protein: "$ 4.3 Mill",
              expenditure: "$ 1.3 Mill",
              actions: "8%",
            },
            {
              name: "ICT Regulatory",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 4.8 Mill",
              protein: "$ 3.9 Mill",
              expenditure: "$ 0.9 Mill",
              actions: "16%",
            },
          ],
          evidences: [
            {
              img: require("@/assets/eacop.jpeg"),
              progress: 'On Progress'
            },
            {
              img: require("@/assets/eacop.jpeg"),
              progress: 'On Hold'
            },
            {
              img: require("@/assets/eacop.jpeg"),
              progress: 'Completed'
            },
          ],
          activityProgress: {
            labels: [
              "Statistical",
              "e-Commerce",
              "ICT Regulatory",
              "e-Commerce",
              "ICT Regulatory",
            ],
            series: [
              {
                name: "Last Month",
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                data: [320, 332, 301, 334, 390, 320, 332, 301, 334, 89, 330, 320],
              },
              {
                name: "This Month",
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                data: [120, 320, 332, 301, 334, 89, 132, 101, 134, 90, 230, 210],
              },
            ]
          }
        },
        jnhpp: {
          name: "JNHPP",
          longForm: "Julius Nyerere Hydropower Project",
          cards: {
            total: 3,
            completed: 2,
            ongoing: 1,
            pending: 0
          },
          contractual: {
            location: "Morogoro",
            contractor: "Arabs Constractor Group",
            duration: 60,
            used: 58,
            remain: 2,
            percent: 40,
            finance: "Sponsor",
          },
          institutions: [
            {
              name: 'TANESCO',
              longForm: 'Tanzania Electric Supply Company Limited',
              logo: require("@/assets/tanesco.png")
            }
          ],
          activities: [
            {
              name: "Statistical Information Management System",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 6.7 Mill",
              protein: "$ 4.3 Mill",
              expenditure: "$ 2.4 Mill",
              actions: "1%",
            },
            {
              name: "e-Commerce",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "5.6",
              protein: "$ 4.3 Mill",
              expenditure: "$ 1.3 Mill",
              actions: "8%",
            },
            {
              name: "ICT Regulatory",
              calories: 26,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 4.8 Mill",
              protein: "$ 3.9 Mill",
              expenditure: "$ 0.9 Mill",
              actions: "16%",
            }
          ],
          evidences: [
            {
              img: require("@/assets/jnhpp.jpeg"),
              progress: 'On Progress'
            },
            {
              img: require("@/assets/jnhpp.jpeg"),
              progress: 'On Hold'
            },
            {
              img: require("@/assets/jnhpp.jpeg"),
              progress: 'Completed'
            },
          ],
          activityProgress: {
            labels: [
              "Statistical",
              "e-Commerce",
              "ICT Regulatory"
            ],
            series: [
              {
                name: "Last Month",
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                data: [320, 332, 301, 334, 390, 320, 332, 301, 334, 89, 330, 320],
              },
              {
                name: "This Month",
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                data: [120, 320, 332, 301, 334, 89, 132, 101, 134, 90, 230, 210],
              },
            ]
          }
        },
        rea: {
          name: "REA",
          longForm: "REA",
          cards: {
            total: 3,
            completed: 2,
            ongoing: 1,
            pending: 0
          },
          contractual: {
            location: "Morogoro",
            contractor: "Arabs Constractor Group",
            duration: 60,
            used: 58,
            remain: 2,
            percent: 20,
            finance: "Sponsor",
          },
          institutions: [
            {
              name: 'TANESCO',
              longForm: 'Tanzania Electric Supply Company Limited',
              logo: require("@/assets/tanesco.png")
            }
          ],
          activities: [
            {
              name: "Statistical Information Management System",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 6.7 Mill",
              protein: "$ 4.3 Mill",
              expenditure: "$ 2.4 Mill",
              actions: "1%",
            },
            {
              name: "e-Commerce",
              calories: 100,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "5.6",
              protein: "$ 4.3 Mill",
              expenditure: "$ 1.3 Mill",
              actions: "8%",
            },
            {
              name: "ICT Regulatory",
              calories: 26,
              fat: "Establishment of the National ICT Professional and Innovation Center",
              carbs: "$ 4.8 Mill",
              protein: "$ 3.9 Mill",
              expenditure: "$ 0.9 Mill",
              actions: "16%",
            }
          ],
          evidences: [
            {
              img: require("@/assets/jnhpp.jpeg"),
              progress: 'On Progress'
            },
            {
              img: require("@/assets/jnhpp.jpeg"),
              progress: 'On Hold'
            },
            {
              img: require("@/assets/jnhpp.jpeg"),
              progress: 'Completed'
            },
          ],
          activityProgress: {
            labels: [
              "Statistical",
              "e-Commerce",
              "ICT Regulatory"
            ],
            series: [
              {
                name: "Last Month",
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                data: [320, 332, 301, 334, 390, 320, 332, 301, 334, 89, 330, 320],
              },
              {
                name: "This Month",
                type: "bar",
                stack: "Ad",
                emphasis: {
                  focus: "series",
                },
                data: [120, 320, 332, 301, 334, 89, 132, 101, 134, 90, 230, 210],
              },
            ]
          }
        },
      },



      show: false,
      Asignmentdialog:false,
      headers: [
        {
          text: "Activity Title",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descriptions", value: "fat" },
        { text: "Budget", value: "carbs" },
        { text: "Disbused Amount", value: "protein" },
        { text: "Commulative Expenditure", value: "expenditure" },
        { text: "Completion in (%)", value: "calories" },
        { text: "Actions", value: "actions" },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
    };
  },

  computed: {
    activeProject() {
      return this.projects[this.$route.params?.projectSlug]
    }
  },

  methods: {

    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },
  },
};
</script>

<style>
.fit-avatar {
  width: 50px !important;
  height: 50px !important;
}
</style>
